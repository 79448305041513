import React from 'react';

function SomethingWentWrong() {
  return (
    <div className="collectionNotFound">
      <p>Something Went Wrong</p>
    </div>
  );
}

export default SomethingWentWrong;
