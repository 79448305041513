import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useResizable } from 'react-resizable-layout';
import LayoutSplitter from '../components/layout/splitter';
import TradingView from '../components/analytics/TradingView';
import CollectionStatisticsView from '../components/analytics/CollectionStatisticsView';
import CollectionNoteView from '../components/analytics/CollectionNoteView';
import CollectionResistanceView from '../components/analytics/CollectionResistanceView';
import CollectionInfoView from '../components/analytics/CollectionInfoView';
import { clearCollectionListings, clearCollectionSales, getCollectionDetails } from '../store/slices/collection';

function AnalyticsPageComponent({
  clearCollectionListingsAction,
  clearCollectionSalesAction,
  getCollectionDetailsAction,
  collectionDetailsData,
}) {
  const { slug } = useParams();
  const leftPanelRef = useRef();
  const rightPanelRef = useRef();

  useEffect(() => {
    clearCollectionListingsAction();
    clearCollectionSalesAction();
  }, [clearCollectionListingsAction, clearCollectionSalesAction]);

  useEffect(() => {
    getCollectionDetailsAction(slug);
  }, [getCollectionDetailsAction, slug]);

  const {
    isDragging: isCollectionViewPanelDragging,
    position: collectionViewPanelWidth,
    splitterProps: collectionViewPanelDragBarProps,
  } = useResizable({
    axis: 'x',
    initial: 300,
    min: 10,
    reverse: true,
  });

  const {
    isDragging: isNotePanelDragging,
    position: notePanelHeight,
    splitterProps: notePanelDragBarProps,
  } = useResizable({
    axis: 'y',
    initial: 170,
    min: 10,
    reverse: true,
    containerRef: leftPanelRef,
  });

  const {
    isDragging: isCollectionLinksPanelDragging,
    position: linksPanelHeight,
    splitterProps: linksPanelDragBarProps,
  } = useResizable({
    axis: 'y',
    initial: 350,
    min: 10,
    reverse: true,
    containerRef: rightPanelRef,
  });

  return (
    <div className="content analytics-page">
      <div className="collection-view-panel" ref={leftPanelRef}>
        <div className="collection-graph-panel">
          <CollectionStatisticsView data={collectionDetailsData} />
          <TradingView data={collectionDetailsData} />
        </div>
        <LayoutSplitter dir="horizontal" isDragging={isNotePanelDragging} {...notePanelDragBarProps} />
        <div
          className={clsx('collection-note-panel', {
            dragging: isNotePanelDragging,
          })}
          style={{ height: notePanelHeight }}
        >
          <CollectionNoteView slug={slug} />
        </div>
      </div>
      <LayoutSplitter isDragging={isCollectionViewPanelDragging} {...collectionViewPanelDragBarProps} />
      <div
        className={clsx('collection-details-panel', {
          dragging: isCollectionViewPanelDragging,
        })}
        style={{ width: collectionViewPanelWidth }}
        ref={rightPanelRef}
      >
        <div className="collection-resistance-panel">
          <CollectionResistanceView slug={slug} />
        </div>
        <LayoutSplitter dir="horizontal" isDragging={isCollectionLinksPanelDragging} {...linksPanelDragBarProps} />
        <div
          className={clsx('collection-links-panel', {
            dragging: isCollectionLinksPanelDragging,
          })}
          style={{ height: linksPanelHeight }}
        >
          <CollectionInfoView data={collectionDetailsData} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    collectionDetailsData: state.collection.collectionDetailsData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCollectionDetailsAction: (payload) => dispatch(getCollectionDetails(payload)),
  clearCollectionListingsAction: (payload) => dispatch(clearCollectionListings(payload)),
  clearCollectionSalesAction: (payload) => dispatch(clearCollectionSales(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPageComponent);
