import React from 'react';
import { connect } from 'react-redux';
import twitterIcon from '../../assets/images/twitter.png';

function Footer({ walletAddress }) {
  return (
    <footer className="footer">
      <p className="copyright">&copy; 2023 dynamic.tools. All rights reserved</p>
      <img src={twitterIcon} className="twitter-icon pointer" alt="Twitter icon" />
      {/* walletAddress && ? (
        <button type="button" className="feedback-button">
          Feedback
        </button>
      ) : null */}
    </footer>
  );
}

const mapStateToProps = (state) => {
  return {
    walletAddress: state.user.walletAddress,
  };
};

export default connect(mapStateToProps)(Footer);
